.amount-imput label[for="amount"] {
    -webkit-transition: background-color 200ms cubic-bezier(0.445, 0.05, 0.55, 0.95), color 200ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
    -moz-transition: background-color 200ms cubic-bezier(0.445, 0.05, 0.55, 0.95), color 200ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
    -ms-transition: background-color 200ms cubic-bezier(0.445, 0.05, 0.55, 0.95), color 200ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
    -o-transition: background-color 200ms cubic-bezier(0.445, 0.05, 0.55, 0.95), color 200ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
    transition: background-color 200ms cubic-bezier(0.445, 0.05, 0.55, 0.95), color 200ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
    border-top: solid 1px #DEE2E5;
    border-bottom: solid 1px #DEE2E5;
    color: #6E787F;
    cursor: pointer;
    display: block;
    font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
    font-weight: 300;
    padding: 12px 14px;
    position: relative;
    margin-top: -11px;
    width: 100%;
    z-index: 1;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: 1.2rem;
    /* margin-bottom: 1rem; */
  }
  
  /* @media (min-width: 720px) { */
    .amount-imput label[for="amount"] {
      border: 1px solid #b5b5b5;
      border-radius: 3px;
    }
  /* } */
  
  .amount-imput label[for="amount"] .input-label {
    -webkit-transition: opacity 0.2s cubic-bezier(0.445, 0.05, 0.55, 0.95);
    -moz-transition: opacity 0.2s cubic-bezier(0.445, 0.05, 0.55, 0.95);
    -ms-transition: opacity 0.2s cubic-bezier(0.445, 0.05, 0.55, 0.95);
    -o-transition: opacity 0.2s cubic-bezier(0.445, 0.05, 0.55, 0.95);
    transition: opacity 0.2s cubic-bezier(0.445, 0.05, 0.55, 0.95);
    color: #000;
    display: block;
    font-size: 12px;
    font-weight: 500;
    line-height: 1;
    margin-bottom: 4px;
  }
  
  .amount-imput label[for="amount"].has-focus {
    background-color: #f9f9f9;
    cursor: text;
    z-index: 2;
    
  }
  
  .amount-imput label[for="amount"].has-focus .input-wrapper:after {
    color: #393536;
  }
  
  .amount-imput  label[for="amount"].has-focus .input-label {
    color: #000;
  }
  
  .amount-imput input {
    background-color: transparent;
    display: block;
    border: none;
    color: #000;
    cursor: pointer;
    font-size: 1.2rem;
    outline: none;
    line-height: 1;
    width: 100%;
  }
  
  .amount-imput .input-wrapper {
    position: relative;
  }
  
  .amount-imput .amount-wrapper input {
    -webkit-transition: transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.2s ease;
    -moz-transition: transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.2s ease;
    -ms-transition: transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.2s ease;
    -o-transition: transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.2s ease;
    transition: transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.2s ease;
    padding: 0 14px;
  }
  
  .amount-imput .amount-wrapper:after {
    -webkit-transition: opacity 0.2s ease;
    -moz-transition: opacity 0.2s ease;
    -ms-transition: opacity 0.2s ease;
    -o-transition: opacity 0.2s ease;
    transition: opacity 0.2s ease;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    content: "$";
    color: #393536;
    display: inline-block;
    line-height: 1;
    left: 0px;
    position: absolute;
    top: 50%;
     
  }

  .amount-imput .errinfo{
    color:#ca2a2a;
    padding: 0;
    margin: 0;
    font-size:13px;
  }