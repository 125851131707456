
.register-form{
    margin:  100px auto;
    padding: 20px;;
    max-width: 500px;
    border:gainsboro 1px solid;
}


.register-form .input-container {
    display: -ms-flexbox; /* IE10 */
    display: flex;
    width: 100%;
    margin-bottom: 15px;
}

.register-form .icon {
    padding: 15px;
    background: dodgerblue;
    color: white;
    min-width: 50px;
    text-align: center;
}

.register-form .input-field {
    width: 100%;
    padding: 10px;
    outline: none;
}

.register-form .input-field:focus {
    border: 2px solid dodgerblue;
}

/* Set a style for the submit button */
.register-form .btn1 {
    background-color: dodgerblue;
    color: white;
    /* padding: 15px 20px; */
    border: none;
    cursor: pointer;
    width: 100%;
    opacity: 0.9;
    /* height: 50px; */
}
.register-form .btn-disable {
    background-color: gray;
    color: white;
    padding: 15px 20px;
    border: none;
    cursor: pointer;
    width: 100%;
    opacity: 0.9;
    height: 50px;
}

.register-form .btn1:hover {
    opacity: 1;
}

.register-form .right{
    float:right;
}
.register-form .form-title{
    font-size: 20px;
    font-weight: bold;
}
.register-form .send-code-btn{
    width: 200px;
}
.register-form .error-info{
    margin-top: -15px;
    font-size: 12px;
    color: red;
}
@media(max-width:768px){
    .register-form{
        margin: 0;
        border: 0;
    }
}
