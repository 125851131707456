.mycontainer{
  width:80%;
  margin: auto;
  overflow: hidden;
}
body{
  font-family: Arial, Helvetica, sans-serif;
  font-size: 15px;
  line-height: 1.5;
  padding:0;
  margin: 0;
  background-color:white;
}