#phoneText, #sms{
    height: 100px;;
}

.input-tip{
    color:gray;
    font-size: 14px;
}

.show-phones{
    background: #e3e3e3!important;
   /* background: currentColor; */
}

