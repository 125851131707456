

.dcard{
  box-shadow: 4px 4px 4px 4px rgba(0, 0, 0, 0.2);

  /* width: 400px; */
  width: 60%;
  /* margin: auto; */
  /* text-align: center; */
  font-family: arial;
  margin: 60px;
  /* float: left; */
}
.dcard-titile{
    /* font-weight: bold; */
    color: white;
    background-color: grey;
    text-align: center;
    /* cursor: pointer; */
    width: 100%;
    font-size: 18px;
    /* display: none; */
}
.dcard-body{
    
    /* padding-top: 40px; */
    text-align: center;
    /* height: 10rem; */
}
.dcard-content{
    padding-top: 2rem;
    font-size: 50px;
    color:green;
}
.dcard-des{
    font-size: 1rem;
    margin: -1.5rem;
    padding-bottom: 5rem;
    color: gray;
}

@media(max-width:768px){
    .dcard{
        width:100%;
        margin: 0 0 50px 0;;
    }
    .dcard-content{
        font-size: 30px;
        color:green;
    }
    .dcard-des{
        font-size:14px;
    }
}
