.profile-form{
    max-width: 600px;
    margin: 30px auto;
    border: #dcdcdc solid 1px;
    padding: 20px;
}
@media(max-width:768px){
    .profile-form{
        padding: 0px;
        margin: 0px;
        border: none;
    }
}