#boxes{
    margin-top:20px;
    min-height: 200px;

}
#boxes .box{
    position: relative;
    box-sizing: inherit;
    float: left;
    width:33%;
    text-align: center;
    align-content: center;
    margin: auto;
   
}
#boxes .box img{
    height: 150px;
}
#boxes .box p{
     padding: 0 15px 0 15px;
     color:#35424a;
     font-size: 16px;
     
}