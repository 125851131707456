.account-title{
    font-size: 25px;
    color:#35424a;
    line-height:60px;
    
}

.account-header{
    margin: 0;
    background-color:gainsboro;
    padding:0px 20px;
    height: 60px;
}
.account-header .logout{
    float: right;
    border: none;
    color: gray;
    font-size: 16px;
    cursor: pointer;
    line-height:60px;
}
.account-header .menu{

    cursor: pointer;
    float: right;
    display: none;

}
.account-header .logout:hover {
    color: orangered;
    text-decoration: none;
}
@media(max-width:768px){
    .account-header{
        position: fixed;
        top:0;
        left:0;
        right: 0;
        z-index: 8;
    }
    .main{
        padding-top: 60px;
    }
    .account-title{
        font-size: 18px;
    }
    .account-header .logout{
        display: none;
    }
    .account-header .menu{
        display: inline;
    }

}