table.blueTable {
    border: 1px solid #1C6EA4;
    background-color: #EEEEEE;
    width: 100%;
    text-align: left;
    border-collapse: collapse;
  }
  table.blueTable td, table.blueTable th {
    border: 0px solid #AAAAAA;
    padding: 3px 2px;
    width: 50%;
    height: 50px;
    padding-left: 10px;
  }
  table.blueTable tbody td {
    font-size: 13px;
   
  }
  table.blueTable tr:nth-child(even) {
    background: #D0E4F5;
  }
  table.blueTable td:nth-child(even) {
    color: #13980D;
    font-weight: bold;
  }
  table.blueTable thead {
    background: #1C6EA4;
    background: -moz-linear-gradient(top, #5592bb 0%, #327cad 66%, #1C6EA4 100%);
    background: -webkit-linear-gradient(top, #5592bb 0%, #327cad 66%, #1C6EA4 100%);
    background: linear-gradient(to bottom, #5592bb 0%, #327cad 66%, #1C6EA4 100%);
  }
  table.blueTable thead th {
    font-size: 15px;
    font-weight: normal;
    color: #FFFFFF;
  }
  table.blueTable thead th:first-child {
    border-left: none;
  }
  
  table.blueTable tfoot td {
    font-size: 14px;
  }
  table.blueTable tfoot .links {
    text-align: right;
  }
  table.blueTable tfoot .links a{
    display: inline-block;
    background: #1C6EA4;
    color: #FFFFFF;
    padding: 2px 8px;
    border-radius: 5px;
  }

  