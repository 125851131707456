.key-line{
    border: 1px solid gainsboro;
    /* width:80%; */
    margin: 20px auto;
    /* padding: 20px; */
    /* overflow:scroll; */
    align-items: center;
 }
 .app-key , .app-secret{
   padding: 20px;
 }
 
 .lable, .value{
    /* border: 1px solid gainsboro; */
     /* margin: 0;     */
     /* margin: 20px; */
    
     
     
 }
 .lable{
      width:30%;
     padding: 10px  10px  10px  10px;
     text-align: right;
     color:gray;
     font-size:16px;
 }
 
 .value{
     /* width:50px; */
     padding: 10px 10px;
     text-align: left;
     color:green;
     font-size:20px;
     font-weight: bold;
 }
 
 .apikeyline-top{
     border-bottom: solid 0.5px gainsboro;
     padding: 15px;
     margin: 0;  
 }
 
 .apikeyline-info{
     padding: 15px;
 }
 
 .apikeyline-title-btn{
     float:right;
     margin-top: -5px;
 }
 .appkeyline-title{
 
 
 
     font-size: 20px;
     font-weight: bold;
 }
 @media(max-width:768px){
     .value{
         padding: 0 5px 0 0;
         display: block;
         font-size: 14px;
     }
     .lable{
         font-size: 15px;
         text-align: left;
         padding: 0;
         width:100%;
         display: block;
     }
 }