.myheader{
    background-color: #35424a;
    color:white;
    padding-top:30px;
    min-height: 110px;
    border-bottom: orangered 3px solid;
}
.myheader a{
    color:white;
    text-decoration: none;
    /* text-transform: uppercase; */
    font-size:16px;
}
.myheader ul{
    margin: 0;
    padding: 0;
    list-style-type: none;
}
.myheader li{
    float: left;
    display: inline;
    padding: 0 20px 0 20px;
}
.myheader #branding{
    float: left;
   
}
.myheader #branding img{
    height: 40px;
    width:166px;
    margin-top: 5px;
}
.myheader #branding h1{
    margin: 0;
    font-size: 30px;
    font-weight: bold;
}
.myheader nav{
    float: right;
    margin-top: 15px;
}

.myheader .highlight, .myheader .current a{
    color:orangered;
    text-decoration: none;
}
.myheader .noselect{
    color:white;
}
.myheader a:hover{
    color:orangered;
}


@media(max-width:768px){
    header #branding,
    header nav,
    /* header nav li, */
    #boxes .box
    {
        float:none;
        text-align: center;
        width:100%;
        margin: auto;
    }
    header{
        padding-bottom: 20px;
    }
    header nav li{

    }
    .mycontainer{
        width:95%;
    }
    header nav li .home{
        display: none;
    }
    
}