#showcase{
    min-height: 400px;
    background-image: url('../img/sms.jpg');
    background-repeat:no-repeat;
    background-size: cover;
    text-align: center;
    color:white;
    /* background-color: blue; */
}
#showcase h1{
    margin-top: 50px;
    font-size: 40px;
    margin-bottom: 10px;
}
#showcase p{
    font-size:16px;
}

.btn1 {
    -webkit-border-radius: 4;
    -moz-border-radius: 4;
    border-radius: 4px;
    color: #ffffff;
    font-size: 16px;
    /* background: #3498db; */
    background: darkgreen;
    padding:10px 20px 10px 20px;
    text-decoration: none;
  }
  
  .btn1:hover {
    /* background: #3cb0fd; */
    background: rgb(255, 60, 0);
    color: #ffffff;
    text-decoration: none;
  }